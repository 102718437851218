<script setup>
import { inject } from 'vue'

const store = inject('store')

</script>

<template>
  <div class="table-responsive">
    <table class="table table-dark table-striped text-center text-grey">
      <thead>
        <tr>
          <th class="text-muted text-uppercase" scope="col">
            <span class="fs-4 fw-bold text-light">{{ store.state.features.name }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-uppercase fw-bold text-grey">
            <span class="h5 fw-bold text-light">{{ store.state.features.firstmonth }}</span><br>
            <span><small>{{ store.state.features.nocontract }}</small></span><br>
            <span><small>{{ store.state.features.nocard }}</small></span>
          </td>
        </tr>
        <tr>
          <td class="text-uppercase fw-bold text-grey">
            <span class="h5 fw-bold text-light">{{ store.state.features.fee }}</span><br>
            <span><small><small>{{ store.state.features.noadvance }}</small></small></span><br>
            <span><small><small>{{ store.state.features.classes }}</small></small></span>
          </td>
        </tr>
        
        <tr>
          <td class="text-uppercase text-grey fw-bold">
            <span class="h5 fw-bold text-light">Contact tutor</span><br>
            <span><small>{{ store.state.tutor.name }}</small></span><br>
            <span><small>{{ store.state.tutor.pg }}</small></span><br>
            <span><small>{{ store.state.brand.part1 }}<span class="text-white"><i>{{ store.state.brand.part2 }}</i></span>{{ store.state.brand.part3 }}<span class="text-secondary">{{ store.state.brand.domain }}</span></small></span>
          </td>
        </tr>

        <tr>
          <td class="bg-black py-2">
            <a :href="`${store.state.brand.mail_to}`" type="button" class="btn btn-secondary w-50 text-uppercase">Email Leo (tutor)</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
