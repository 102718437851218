import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import FeeView from '../views/FeeView'

const routes = [
  { path: '/', component: HomeView },
  { path: '/fee', component: FeeView },
  { path:'/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router