<script setup>
import { inject } from 'vue';

const store = inject('store');
</script>

<template>
  <router-link to="/" class="navbar-brand text-light text-uppercase">
    <span class="fs-1">{{ store.state.brand.part1 }}<span class="text-secondary">{{ store.state.brand.part2 }}</span>
    </span><br>
    <span class="text-grey">{{ store.state.brand.caption }}</span>
  </router-link>

  <div class="d-flex">
    <span class="fs-4 text-secondary text-uppercase">{{ store.state.brand.levels }}</span>
  </div>
</template>